/* Desktop CSS */
@media (min-width: 601px) {
    .square {
        height: 50px;
        width: 50px;
        background-color: transparent;
        border: none;
        font-size: 30px;
    }

    .board {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: fit-content;
        height: fit-content;
        border-top: 1px solid;
        border-right: 1px solid;
    }
    .board-row {
        display: flex;
        height: 50px;
    }
    .board-row .square {
        border-left: 1px solid;
        border-bottom: 1px solid;
    }

    .restart-button {
        height: 24px;
        width: 36px;
        background-color: transparent;
        border: none;
        box-shadow: 0 1px 1px 1px lightgrey;
        margin-right: 5px;
    }
    .restart-button:hover {
        cursor: pointer;
    }
    .restart-button:active {
        box-shadow: 0 1px 1px 1px rgba(211, 211, 211, 0.939);
        transform: translateY(1px);
    }
    .restart-icon {
        height: 20px;
    }
    .rotate {
        transform: rotate(-180deg);
    }

    .game-container {
        text-align: center;
        width: 180px !important;
        margin: 0 20px;
    }
    .game-message {
        height: 36px;
        align-content: center;
    }

    .control-panel-stack {
        border: 1px solid lightgrey;
        padding: 5px 8px;
    }
    .control-panel-title {
        margin-bottom: 10px;
        text-decoration: underline;
    }
    .control-panel-item {
        width: 180px;
        height: fit-content;
    }
    .control-panel-form {
        align-items: center;
        display: flex;
        height: max-content;
    }
    .control-panel-restart-form {
        width: 180px;
        height: 24px;
        align-items: center;
        display: flex;
        margin-bottom: 10px;
    }

    .lose-popup {
        position: absolute;
        top: 300px;
    }
    .lose-popup-ack {
        font-size: 16px;
        display: flex;
        text-align: center;
        height: 20px;
        margin-right: auto;
        margin-left: auto;
        cursor: pointer;
    }

    .game-title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }
    .game-subtitle {
        text-align: center;
        font-size: 12px;
        font-style: italic;
    }
    .game-count {
        text-align: center;
        font-size: 16px;
    }
}

/* Mobile CSS */
@media (max-width: 600px) {
    .square {
        height: 30px;
        width: 30px;
        background-color: transparent;
        border: none;
        font-size: 15px;
    }

    .board {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: fit-content;
        height: fit-content;
        border-top: 1px solid black;
        border-right: 1px solid black;
    }
    .board-row {
        display: flex;
        height: 30px;
    }
    .board-row .square {
        border-left: 1px solid;
        border-bottom: 1px solid;
    }

    .restart-button {
        height: 18px;
        width: 27px;
        background-color: transparent;
        border: none;
        box-shadow: 0 1px 1px 1px lightgrey;
        margin-right: 13px;
    }
    .restart-button:hover {
        cursor: pointer;
    }
    .restart-button:active {
        box-shadow: 0 1px 1px 1px rgba(211, 211, 211, 0.939);
        /* transform: translateY(1px); */
    }
    .restart-icon {
        height: 15px;
    }
    .rotate {
        transform: rotate(-180deg);
    }

    .game-container {
        text-align: center;
        width: 180px !important;
        margin: 0 auto;
    }
    .game-message {
        height: 36px;
        align-content: center;
    }

    .control-panel-stack {
        border: 1px solid lightgrey;
        padding: 5px 8px;
        margin: 20px auto;
    }
    .control-panel-title {
        margin-bottom: 10px;
        text-decoration: underline;
    }
    .control-panel-item {
        width: 180px;
        height: fit-content;
    }
    .control-panel-form {
        align-items: center;
        display: flex;
        height: max-content;
    }
    .control-panel-restart-form {
        width: 180px;
        height: 24px;
        align-items: center;
        display: flex;
        margin-bottom: 10px;
    }

    .lose-popup {
        position: absolute;
        top: 300px;
    }
    .lose-popup-ack {
        font-size: 16px;
        display: flex;
        text-align: center;
        height: 20px;
        margin-right: auto;
        margin-left: auto;
        cursor: pointer;
    }

    .game-title {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
    }
    .game-subtitle {
        text-align: center;
        font-size: 12px;
        font-style: italic;
    }
    .game-count {
        text-align: center;
        font-size: 16px;
    }
}