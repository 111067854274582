.header-bar {
    position: fixed;
    height: 120px;
    width: 100%;
    background-color: #ffff;
    box-shadow: 0 1px 1px 1px lightgrey;
}

.title-bar {
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0 15% 0 15%;
    height: 70px;
    width: auto;
    background-color: #bea74c;
}

.title-area {
    text-align: left;
    flex-grow: 1;
}
.title-area-title {
    font-size: 24px;
    color: #ffff;
    width: fit-content;
}
.title-area-subtitle {
    font-size: 14px;
    font-style: italic;
    color: #ffff;
    width: fit-content;
}

.logo-application {
    height: 40px;
    margin-right: 12px;
}
.gif-application {
    height: 40px;
}

.logo-user {
    height: 20px;
    color: #ffff;
    filter: invert(100%);
}

.menu-bar {
    display: flex;
    align-items: center;
    padding: 0 15% 0 15%;
    height: 50px;
    width: auto;
    background-color: #fef1c0;
}
.menu-item {
    background-color: none;
    list-style: none;
    padding: 3px 5px;
    font-size: 16px;
    text-align: left;
}
.menu-item a {
    text-decoration: none;
    color: inherit;
    padding: 5px;
}
.menu-item:hover {
    text-decoration: underline;
}

.button {
    display: flex;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    border: none;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
}

.dropdown {
    display: none;
    position: absolute;
    top: 98px;
    font-size: inherit;
    z-index: 999;
    white-space: nowrap;
    list-style: none;
    background-color: white;
    box-shadow: 0 1px 1px 2px lightgrey;
    border-radius: 3px;
    width: auto;
    padding: 5px;
}
.dropdown.show {
    display: block;
}
.sub-dropdown {
    display: none;
    position: absolute;
    left: -55px;
    top:10px;
    font-size: inherit;
    z-index: 999;
    white-space: nowrap;
    list-style: none;
    background-color: white;
    box-shadow: 0 1px 1px 2px lightgrey;
    border-radius: 3px;
    width: auto;
    padding: 5px;
}
.sub-dropdown.show {
    display: block;
}

body {
    background-color: #e9e2d2;
}

.content-background {
    display: flex;
    padding: 125px 18% 5px 18%;
    width: auto;
}
.content-block {
    background-color: white;
    display: flex;
    flex-grow: 1;
    padding: 2% 0 2% 0;
    min-height: calc(96vh - 130px);
    overflow-y: scroll;
}
.content {
    display: block;
    margin-left: auto;
    margin-right: auto;
}